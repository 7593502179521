import { Controller } from "stimulus"

import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = [
    "checkboxOption",
    "radioOption",
    "displayPhoneNumber",
    "displayEmail",
    "displayWebsite",
    "displayLocation",
    "locationState",
    "locationCity",
    "zipcodeErrors"
  ]

  initialize() {
    this.checkboxOptionTargets.forEach( elem => this.checkboxDisplayField(elem));
    this.radioOptionTargets.forEach( elem => this.radioDisplayField(elem));
  }

  displayField(field, style) {
    this[field].style.display = style;
  }

  checkboxDisplayField(elem) {
    this.displayField(elem.dataset.field, elem.checked ? 'unset' : 'none');
  }

  radioDisplayField(elem) {
    let checked = Array.from(document.getElementsByName(elem.name)).find( e => e.checked)
    this.displayField(elem.dataset.field, checked.value == "true" ? 'unset' : 'none');
  }

  checkField() {
    this.checkboxDisplayField(event.target);
  }

  chooseField() {
    this.radioDisplayField(event.target);
  }
}
