import Dropzone from "dropzone";
import { Controller } from "stimulus";
import { DirectUpload } from "@rails/activestorage";
import {
  getMetaValue,
  toArray,
  findElement,
  removeElement,
  insertAfter
} from "helpers";

Dropzone.autoDiscover = false

export default class extends Controller {
  static targets = ["input"];

  connect() {
    this.dropZone = createDropZone(this);
    this.hideFileInput();
    this.bindEvents();
  }

  // Private
  hideFileInput() {
    this.did_error = false;
    this.inputTarget.disabled = true;
    this.inputTarget.style.display = "none";
    this.submitButton.disabled = true
  }

  remove_file(file) {
    if (this.dropZone.getRejectedFiles().length == 0) {
      this.submitButton.disabled = false;
    }
    if (this.dropZone.files.length == 0) {
      this.did_error = false;
      this.submitButton.disabled = true;
    }
    file.controller && removeElement(file.controller.hiddenInput);
  }

  bindEvents() {
    this.dropZone.on("addedfile", file => {
      if (this.dropZone.files[1]!=null && this.dropZone.options.maxFiles == 1) {
        this.dropZone.removeFile(this.dropZone.files[0])
      }
      this.submitButton.classList.add("is-loading")
      document.getElementById("loading").classList.remove('hidden')
      if(document.getElementById("saveDisabled") != null){
        document.getElementById("saveDisabled").classList.add('hidden')
        document.getElementById("save").classList.add('hidden')
        document.getElementById("saved").classList.add('hidden')
      }
      else if(document.getElementById("updateDisabled") != null){
        document.getElementById("updateDisabled").classList.add('hidden')
        document.getElementById("update").classList.add('hidden')
        document.getElementById("updated").classList.add('hidden')
      }
      setTimeout(() => {
        file.accepted && createDirectUploadController(this, file).start();
      }, 500);
    });

    this.dropZone.on("canceled", file => {
      document.querySelectorAll("span[data-dz-errormessage]").forEach(function (span, index) {
        if (span.innerHTML == 'Upload canceled.') {
          span.innerHTML = ""
        }
      })
    });

    this.dropZone.on("removedfile", file => {
      this.remove_file(file);
    });

    this.dropZone.on("processing", (file) => {
      this.submitButton.disabled = true
    })

    this.dropZone.on("error", (file, error) => {
      this.did_error = true;
      this.submitButton.classList.remove("is-loading");
    });

    this.dropZone.on("queuecomplete", (file) => {
      if (this.dropZone.options.photoUpload == true) {
      }
      this.disable_submit();
      this.submitButton.classList.remove("is-loading");
      document.getElementById("loading").classList.add('hidden')
    });
  }

  get headers() {
    return { "X-CSRF-Token": getMetaValue("csrf-token") };
  }

  get url() {
    return this.inputTarget.getAttribute("data-direct-upload-url");
  }

  get maxFiles() {
    return this.data.get("maxFiles") || 1;
  }

  get maxFileSize() {
    return this.data.get("maxFileSize") || 10; // size in MBs
  }

  get photoUpload() {
    return this.data.get("photoUpload");
  }

  get acceptedFiles() {
    return this.data.get("acceptedFiles");
  }

  get addRemoveLinks() {
    return this.data.get("addRemoveLinks") || false;
  }

  get thumbnailHeight() { return this.data.get("thumbnailHeight") || 550 }

  get form() { return this.element.closest("form") }

  get submitButton() { return findElement(this.form, "button[type=submit]") }

  disable_submit() {
    const event = new CustomEvent("image-added")
    window.dispatchEvent(event)

    if (this.did_error == true) {
      this.submitButton.disabled = true
    }  else {
      this.submitButton.disabled = false
    }
  }
}

class DirectUploadController {
  constructor(source, file) {
    this.directUpload = createDirectUpload(file, source.url, this);
    this.source = source;
    this.file = file;
  }

  start() {
    this.file.controller = this;
    this.hiddenInput = this.createHiddenInput();
    this.directUpload.create((error, attributes) => {
      if (error) {
        removeElement(this.hiddenInput);
        this.emitDropzoneError(error);
      } else {
        this.hiddenInput.value = attributes.signed_id;
        this.emitDropzoneSuccess();
      }
    });
  }

  createHiddenInput() {
    const input = document.createElement("input");
    input.type = "hidden";
    input.name = this.source.inputTarget.name;
    insertAfter(input, this.source.inputTarget);
    return input;
  }

  directUploadWillStoreFileWithXHR(xhr) {
    this.bindProgressEvent(xhr);
    this.emitDropzoneUploading();
  }

  bindProgressEvent(xhr) {
    this.xhr = xhr;
    this.xhr.upload.addEventListener("progress", event =>
      this.uploadRequestDidProgress(event)
    );
  }

  uploadRequestDidProgress(event) {
    const element = this.source.element
    const progress = event.loaded / event.total * 100
    findElement(this.file.previewTemplate, ".dz-upload").style.width = `${progress}%`
  }

  emitDropzoneUploading() {
    this.file.status = Dropzone.UPLOADING;
    this.source.dropZone.emit("processing", this.file);
  }

  emitDropzoneError(error) {
    this.file.status = Dropzone.ERROR;
    this.source.dropZone.emit("error", this.file, error);
    this.source.dropZone.emit("complete", this.file);
  }

  emitDropzoneSuccess() {
    this.file.status = Dropzone.SUCCESS;
    this.source.dropZone.emit("success", this.file);
    this.source.dropZone.emit("complete", this.file);
  }
}

function createDirectUploadController(source, file) {
  return new DirectUploadController(source, file);
}

function createDirectUpload(file, url, controller) {
  return new DirectUpload(file, url, controller);
}

function createDropZone(controller) {
  return new Dropzone(controller.element, {
    url: controller.url,
    headers: controller.headers,
    maxFiles: controller.maxFiles,
    maxFilesize: controller.maxFileSize,
    acceptedFiles: ".jpeg,.jpg,.png",
    thumbnailMethod: 'crop',
    thumbnailWidth: controller.thumbnailWidth,
    thumbnailHeight: controller.thumbnailHeight,
    addRemoveLinks: controller.removeLinks,
    autoQueue: false,
    chunking: true,
    parallelChunkUploads: true,
    previewTemplate: document.querySelector('.preview-template').innerHTML,
    previewsContainer: ".previews",
  });
}
