import { Toggle } from "tailwindcss-stimulus-components"

export default class extends Toggle {
  static targets = [
    'button',
    'postButton',
    'toggleable'
  ]

  toggle(event) {
    this.postButtonTarget.classList.add('hidden')
    super.toggle(event)
  }

  cancelPost(){
    this.postButtonTarget.classList.remove('hidden')
    this.toggleableTarget.classList.add('hidden')
    document.getElementById('post_title').value = ""
    document.getElementById('post_body').value = ""
  }
}
