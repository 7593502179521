import { Controller } from 'stimulus'
import PhotoSwipeLightbox from 'photoswipe/lightbox'
import PhotoSwipeDynamicCaption from 'photoswipe-dynamic-caption-plugin'

export default class extends Controller {

  connect(){
    const lightbox = new PhotoSwipeLightbox({
      gallery: '#gallery--getting-started',
      children: 'a',
      // initialZoomLevel: 'fit',

      imageClickAction: 'close',
      tapAction: 'close',

      pswpModule: () => import('photoswipe'),

      paddingFn: (viewportSize) => {
        return {
          top: 30, bottom: 30, left: 70, right: 70
        }
      },
    });

    const captionPlugin = new PhotoSwipeDynamicCaption(lightbox, {
      // Plugins options, for example:
      type: 'auto',
    });

    lightbox.init();
  }
}
