// Load all the controllers within this directory and all subdirectories.
// Controller files must be named *_controller.js.

import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"

const application = Application.start()
const context = require.context("controllers", true, /_controller\.js$/)
const contextComponents = require.context("../../components", true, /_controller\.js$/)
application.load(
  definitionsFromContext(context).concat(
    definitionsFromContext(contextComponents)
  )
)

// Import and register all TailwindCSS Components
import { Dropdown, Modal, Slideover, Toggle } from "tailwindcss-stimulus-components"
application.register('dropdown', Dropdown)
application.register('modal', Modal)
application.register('slideover', Slideover)
application.register('toggle', Toggle)

// // Import and register all TailwindCSS Components
// import { Alert, Autosave, Dropdown, Modal, Tabs, Popover, Toggle, Slideover } from "tailwindcss-stimulus-components"
// application.register('alert', Alert)
// application.register('autosave', Autosave)
// application.register('dropdown', Dropdown)
// application.register('modal', Modal)
// application.register('tabs', Tabs)
// application.register('popover', Popover)
// application.register('toggle', Toggle)
// application.register('slideover', Slideover)

import PasswordVisibility from "stimulus-password-visibility"
import Reveal from "stimulus-reveal-controller"
import Clipboard from 'stimulus-clipboard'
import PhotoSwipeLightbox from 'photoswipe/lightbox'
import PhotoSwipeDynamicCaption from 'photoswipe-dynamic-caption-plugin'
import Sortable from 'stimulus-sortable'
import Notification from 'stimulus-notification'

import 'photoswipe/style.css';

application.register("reveal", Reveal)
application.register("password-visibility", PasswordVisibility)
application.register('clipboard', Clipboard)
application.register('sortable', Sortable)
application.register('notification', Notification)

import LocalTime from "local-time"
LocalTime.start()
