// Based on https://onrails.blog/2018/05/08/stimulus-js-tutorial-dont-lose-unsaved-form-fields/

import { Controller } from "stimulus"

const LEAVING_PAGE_MESSAGE = "Your changes will be lost. Are you sure you want to navigate away?"

export default class extends Controller {
  static targets = ['saveDisabled',
                    'save',
                    'saving',
                    'saved',
                    "addAdditional"
                  ]

  formIsChanged(event) {
    this.setChanged("true")
    this.removeErrors()
    this.saveDisabledTarget.classList.add("hidden")
    this.savedTarget.classList.add("hidden")
    this.saveTarget.classList.remove("hidden")
    if (this.hasAddAdditionalTarget) {
      this.addAdditionalTarget.classList.add("hidden")
    }
  }

  eventHandler(event) {
    this.formIsChanged(event)
  }

  allowFormSubmission(event) {
    this.setChanged("false")
  }

  leavingPage(event) {
    if (this.isFormChanged()) {
      if (event.type == "turbo:before-visit") {
        if (!window.confirm(LEAVING_PAGE_MESSAGE)) {
          event.preventDefault()
        }
      } else {
        event.returnValue = LEAVING_PAGE_MESSAGE;
        return event.returnValue;
      }
    }
  }

  setChanged(changed) {
    this.data.set("changed", changed)
  }

  isFormChanged() {
    return this.data.get("changed") == "true"
  }

  removeErrors(){
    document.querySelectorAll('.error_explanation').forEach((element) => {
      element.remove()
    });
    document.querySelectorAll('.field_with_errors').forEach((element) => {
      element.classList.remove('field_with_errors')
    });
  }

  submit(){
    this.saveTarget.classList.add("hidden")
    this.savingTarget.classList.remove("hidden")
  }

  reset() {
    this.savingTarget.classList.add("hidden")
    this.savedTarget.classList.remove("hidden")
    this.setChanged("false")
  
    if (this.hasAddAdditionalTarget) {
      this.addAdditionalTarget.classList.remove("hidden")
    }
  }
}
