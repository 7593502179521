import { Toggle } from "tailwindcss-stimulus-components"

export default class Submenu extends Toggle {
  static targets = [
    'navigable'
  ]

  navigate(event) {
    event.preventDefault()
    this.navigableTargets.forEach(target => {
      target.classList.toggle("hidden")
    });
  }
}
