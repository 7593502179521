import { Controller } from "stimulus"
import { FetchRequest } from '@rails/request.js'

export default class extends Controller {
  static targets = ["filter", "search"]

  connect() {
    let selectedFilter = ""
  }

  async attemptSearch(event) {
    event.preventDefault()

    this.filterTargets.forEach((el, i) => {
      // el.classList.toggle("active", event.target == el )
      if (el.checked == true) {
        this.selectedFilter = el.value;
      }
    })

    const search = this.searchTarget.value
    const filter = this.selectedFilter ?? ''
    const path = this.data.get("path")
    const request = new FetchRequest('get', `${path}/?search=${search}&filter=${filter}`)
    await request.perform()
  }

  async clearSearch(event) {
    event.preventDefault()
    const search = ''
    const filter = ''
    const path = this.data.get("path")
    const request = new FetchRequest('get', `${path}/?search=${search}&filter=${filter}`)
    await request.perform()
  }
}
