import { Toggle } from "tailwindcss-stimulus-components"

export default class NavigationToggle extends Toggle {
  static targets = [
    'toggleable',
    'animatesvg'
  ]

  initialize() {
    let hide_nav = JSON.parse(localStorage.getItem('hideNav'))
    if (hide_nav === true) {
      this.persistNavPref(true, false)
      this.persistSvgPref(true, 'none')
    }
  }

  showHideNav() {
    if(this.toggleableTarget.classList.contains("invisible")) {
      this.persistNavPref(false, true)
      this.persistSvgPref(false, 'rotate(180deg)')
    } else {
      this.persistNavPref(true, false)
      this.persistSvgPref(true, 'none')
    }
  }

  persistNavPref(hidden, hstyle) {
    localStorage.setItem('hideNav', hidden)
    let hide_nav = JSON.parse(localStorage.getItem('hideNav'))

    if(hidden === true) {
      this.toggleableTarget.classList.add("invisible")
      this.toggleableTarget.classList.add("h-0")
    } else {
      this.toggleableTarget.classList.remove("invisible")
      this.toggleableTarget.classList.remove("h-0")
    }
  }

  persistSvgPref(hidden, style) {
    localStorage.setItem('animateSvg', hidden);
    this.animatesvgTargets.forEach((e) => { e.style.transform = style })
  }
}
