import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['title', 'description', 'titleBox', 'descriptionBox']

  initialize() {
    this.updateTitle();
    this.updateDescription();
  }

  updateTitle() {
    this.titleBoxTarget.textContent = this.titleTarget.value
  }

  updateDescription() {
    this.descriptionBoxTarget.textContent = this.descriptionTarget.value
  }
}
