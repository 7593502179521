import { Controller } from "stimulus"
import parameterize from 'parameterize-string'

export default class extends Controller {
  static targets = ['slug', 'slugBox']

  initialize() {
    this.updateSlug()
  }

  updateSlug() {
    // Make this variable depending on if in development/staging/production
    var page_url = "https://www.jutepages.com/work/"
    var parameterized_url = parameterize(this.slugTarget.value)
    this.slugBoxTarget.value = page_url.concat("", parameterized_url)
  }

  copy(event) {
    event.preventDefault()
    navigator.clipboard.writeText(this.slugBoxTarget.value)
  }
}
