import { Controller } from "stimulus"
import Tagify from '@yaireo/tagify'
let debounce = require('lodash/debounce');

export default class extends Controller {
  static targets = [ "filter", "result", "searchItems"]

  connect() {
    var input = this.filterTarget
    var category = document.getElementById("category_name")
    this.path  = this.data.get("path")
    var tagify = new Tagify(input, {whitelist:[], mode:"select"})
    this.tagify = tagify
    this.onInput = debounce(this.onInput, 500).bind(this)
    this.tagify.on('input', this.onInput).on('dropdown:select')
  }

  onInput(e) {
    var value = e.detail.value
    this.tagify.whitelist = null // reset the whitelist
    this.tagify.loading(true)

    fetch(`${this.path}/?search=${value}`)
      .then(RES => RES.json())
      .then((newWhitelist) => {
        if(newWhitelist == ""){
          category.classList.add("text-red-500")
        }
        else{
          category.classList.remove("text-red-500")
        }
        this.tagify.whitelist = newWhitelist
        this.tagify.loading(false)
        this.tagify.dropdown.show()
      })
    }

}
