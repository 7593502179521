import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["option", "checkbox", "daysOpen", "timesOpen", "submit", "template"];

  appointments_and_247() {
    this.daysOpenTarget.style.display = 'none';
    this.timesOpenTarget.style.display = 'none';
    this.submitTarget.style.display = 'unset';
  }

  regular_hours() {
    this.daysOpenTarget.style.display = 'unset';
  }

  initialize_regular_hours(elem) {
    this.checkboxTargets.forEach( (elem) => {
        if (elem.checked == true) {
          this.timesOpenTarget.style.display = 'unset';
          this.submitTarget.style.display = 'unset';
          document.getElementById("operating-schedule-"+elem.value).style.display = 'unset';
        }
      })
  }

  default_hide_all(){
    this.daysOpenTarget.style.display = 'none';
    this.timesOpenTarget.style.display = 'none';
    this.submitTarget.style.display = 'none';
  }

  initialize() {
    let selected_option = ""
    this.optionTargets.forEach( (elem) => {
      if (elem.checked == true) {
        selected_option = elem.value;
      }
    })
    switch (selected_option) {
      case 'regular_hours':
        this.regular_hours();
        this.initialize_regular_hours(this.elem);
        break;
      case 'appointment_only':
        this.appointments_and_247();
        break;
      case '24/7 Service':
        this.appointments_and_247();
        break;
      default:
        this.default_hide_all();
    }
  }

  selectRadioOption(event) {
    switch (event.target.value) {
      case 'regular_hours':
        this.regular_hours();
        this.hideTimeAndSubmit();
        break;
      case 'appointment_only':
        this.appointments_and_247();
        break;
      case '24/7 Service':
        this.appointments_and_247();
        break;
      default:
        console.warn("Unexpected value", event.target.value);
    }
  }

  hideTimeAndSubmit() {
    let none_checked = true
    this.checkboxTargets.forEach( (elem) => {
      if (elem.checked == true) {
        none_checked = false;
      }
    })
    if (none_checked == true) {
      this.timesOpenTarget.style.display = 'none';
      this.submitTarget.style.display = 'none';
    } else {
      this.timesOpenTarget.style.display = 'unset';
    }
  }

  addOpenClosingTime(event) {
    if (event.target.checked == true) {
      this.submitTarget.style.display = 'unset';
      this.timesOpenTarget.style.display = 'unset';
      document.getElementById("operating-schedule-"+event.target.value).style.display = 'unset';
    } else {
      document.getElementById("operating-schedule-"+event.target.value).style.display = 'none';
    }
    this.hideTimeAndSubmit();
  }


}
