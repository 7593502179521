import { Controller } from "stimulus"

export default class extends Controller {
    static targets = ["yearlyLabel", "monthlyLabel",
                      "yearlyPlan", "monthlyPlan"]

  connect() {}

  selectRadioOption(event) {
    if (this.yearlyPlanTarget.checked == true) {
      this.yearlyLabelTarget.textContent = "Selected"
      this.monthlyLabelTarget.textContent = "Monthly"
    }

    if (this.monthlyPlanTarget.checked == true) {
      this.monthlyLabelTarget.textContent = "Selected"
      this.yearlyLabelTarget.textContent = "Yearly"
    }
  }
}
