import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["option", "expectation", "suggestion", "other"]

  initialize() {
    let selected_option = ""
    this.optionTargets.forEach( (elem) => {
      if (elem.checked == true) {
        selected_option = elem.value;
      }
    })
    switch (selected_option) {
      case 'unexpected':
        this.expectation();
        break;
      case 'suggestion':
        this.suggestion();
        break;
      case 'other':
        this.other();
        break;
      default:
        this.default_hide_all();
    }
  }

  default_hide_all(){
    this.expectationTarget.display = 'hidden';
    this.suggestionTarget.display = 'hidden';
    this.otherTarget.style.display = 'hidden';
  }

  expectation() {
    this.suggestionTarget.classList.add('hidden');
    this.otherTarget.classList.add('hidden');
    this.expectationTarget.classList.remove('hidden');
  }

  suggestion() {
    this.expectationTarget.classList.add('hidden');
    this.otherTarget.classList.add('hidden');   
    this.suggestionTarget.classList.remove('hidden');
  }

  other() {
    this.expectationTarget.classList.add('hidden');
    this.suggestionTarget.classList.add('hidden');    
    this.otherTarget.classList.remove('hidden');
  }

  selectRadioOption(event) {
    switch (event.target.value) {
      case 'unexpected':
        this.expectation();
        break;
      case 'suggestion':
        this.suggestion();
        break;
      case 'other':
        this.other();
        break;
      default:
        console.warn("Unexpected value", event.target.value);
    }
  }
}