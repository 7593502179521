import { Controller } from "stimulus"

export default class extends Controller {
    static targets = ["cardElement", "expiryElement", "cvcElement",
                      "cardErrors", "form"]

  connect() {
    this.setupStripe()
  }

  setupStripe() {
   const stripe_key = this.data.get('key')
   const stripe = Stripe(stripe_key)

   let controller = this

   const elements = stripe.elements()

   var style = {
     base: {
       color: '#32325d',
       fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
       fontSmoothing: 'antialiased',
       fontSize: '16px',
       '::placeholder': {
         color: '#aab7c4'
       }
     },
     invalid: {
       color: '#fa755a',
       iconColor: '#fa755a'
     }
   }

   const cardNumberElement = elements.create('cardNumber', {
                                              style: style,
                                              showIcon: true,
                                              placeholder: 'Card Number',
                                            })
   cardNumberElement.mount(this.cardElementTarget)

   const cardExpiryElement = elements.create('cardExpiry', {
         style: style
       })
   cardExpiryElement.mount(this.expiryElementTarget)

   const cardCvcElement = elements.create('cardCvc', {
         style: style
       })
   cardCvcElement.mount(this.cvcElementTarget)

   var displayError = controller.cardErrorsTarget

   cardNumberElement.addEventListener('change', (event) => {
     if (event.error) {
       displayError.textContent = event.error.message
     } else {
       displayError.textContent = ''
     }
   })

   const form = controller.formTarget
   let paymentIntentId = form.dataset.paymentIntent
   let setupIntentId = form.dataset.setupIntent

   if (paymentIntentId) {
     if (form.dataset.status == "requires_action") {
       stripe.confirmCardPayment(paymentIntentId, { setup_future_usage: 'off_session' }).then((result) => {
         if (result.error) {
           displayError.textContent = result.error.message
           form.querySelector("#card-details").classList.remove("d-none")
         } else {
           form.submit()
         }
       })
     }
   }

   form.addEventListener('submit', (event) => {
     event.preventDefault()

     let data = {
       payment_method_data: {
         card: cardNumberElement,
       }
     }

     // Complete a payment intent
     if (paymentIntentId) {
       stripe.confirmCardPayment(paymentIntentId, {
         payment_method: data.payment_method_data,
         setup_future_usage: 'off_session',
         save_payment_method: true,
       }).then((result) => {
         if (result.error) {
           displayError.textContent = result.error.message
           form.querySelector("#card-details").classList.remove("d-none")
         } else {
           form.submit()
         }
       })

     // Updating a card or subscribing with a trial (using a SetupIntent)
     } else if (setupIntentId) {
       stripe.confirmCardSetup(setupIntentId, {
         payment_method: data.payment_method_data
       }).then((result) => {
         if (result.error) {
           displayError.textContent = result.error.message
         } else {
           controller.addHiddenField(form, "payment_method_token", result.setupIntent.payment_method)
           form.submit()
         }
       })

     } else {
       // Subscribing with no trial
       data.payment_method_data.type = 'card'
       stripe.createPaymentMethod(data.payment_method_data).then((result) => {
         if (result.error) {
           displayError.textContent = result.error.message
         } else {
           controller.addHiddenField(form, "payment_method_token", result.paymentMethod.id)
           form.submit()
         }
       })
     }
   })
  }

  addHiddenField(form, name, value) {
    let input = document.createElement("input")
    input.setAttribute("type", "hidden")
    input.setAttribute("name", name)
    input.setAttribute("value", value)
    form.appendChild(input)
  }
}
