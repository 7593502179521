import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["source"]

  copy() {
    const event = new CustomEvent('linkCopied')
    window.dispatchEvent(event)
    navigator.clipboard.writeText(this.sourceTarget.value)
  }
}
