import { Controller } from 'stimulus';
import intlTelInput from 'intl-tel-input';

export default class extends Controller {
    static targets = [ "input" ]

    connect() {
      const input = this.inputTarget;
      intlTelInput(input, {
          utilsScript: "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.13/js/utils.min.js",
          // initialCountry: "us",
          preferredCountries: ["us", "ca", "mx", "gb"],
          separateDialCode: true,
          formatOnDisplay: true,
          autoPlaceholder: "polite",
          hiddenInput: "phone_number",
      });
    }
  }
