import { Controller } from "stimulus"

var sideBarColor;
var buttonColor;

export default class extends Controller {
  static targets = [
    'sideBar',
    'button',
    'button2',
    'button3'
  ]

  initialize() {
    this.updateColor()
  }

  updateColor() {
    let clicked_palette = document.querySelector('input[name="jute_page[palette]"]:checked').value
    this.assign_colors(clicked_palette)
    this.sideBarTarget.classList.replace(this.sideBarTarget.classList[1], sideBarColor)
    this.buttonTarget.classList.replace(this.buttonTarget.classList[4], buttonColor)
    this.button2Target.classList.replace(this.button2Target.classList[4], buttonColor)
    this.button3Target.classList.replace(this.button3Target.classList[4], buttonColor)
  }

  assign_colors(clicked_palette){
    switch(clicked_palette){
      case "none_default":
        sideBarColor = "bg-gray-dark"
        buttonColor = "bg-sea-darker"
        break;
      case "light_sea":
        sideBarColor = "bg-sea-lighter"
        buttonColor = "bg-sea-darker"
        break;
      case "light_lilac":
        sideBarColor = "bg-lilac-lighter"
        buttonColor = "bg-lilac-darker"
        break;
      case "light_gold":
        sideBarColor = "bg-gold-lighter"
        buttonColor = "bg-gold"
        break;
      case "light_clay":
        sideBarColor = "bg-clay-lighter"
        buttonColor = "bg-clay-darker"
        break;
      case "light_moss":
        sideBarColor = "bg-moss-lighter"
        buttonColor = "bg-moss-darker"
        break;
      case "dark_sea":
        sideBarColor = "bg-sea-darker"
        buttonColor = "bg-sea-lighter"
        break;
      case "dark_lilac":
        sideBarColor = "bg-lilac-darker"
        buttonColor = "bg-lilac-lighter"
        break;
      case "dark_gold":
        sideBarColor = "bg-gold-darker"
        buttonColor = "bg-gold-lighter"
        break;
      case "dark_clay":
        sideBarColor = "bg-clay-darker"
        buttonColor = "bg-clay-lighter"
        break;
      case "dark_moss":
        sideBarColor = "bg-moss-darker"
        buttonColor = "bg-moss-lighter"
        break;

    }
  }
}