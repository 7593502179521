import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    "container",
    "tip",
    "tipBody",
    "showHideTextButton",
    "nextTip",
    "prevTip"
  ];

  initialize() {
    this.showSlide(0)
    let hide_tips = JSON.parse(localStorage.getItem('hideTips'));
    if (hide_tips) {
      this.persistTipPref(true, 'none', 'Show Tips')
    } else {
      this.persistTipPref(false, 'unset', 'Hide Tips')
    }
  }

  next() {
    this.showSlide(this.index + 1)
  }

  prev() {
    this.showSlide(this.index - 1)
  }

  showSlide(index) {
    this.index = index
    if (this.index === (this.tipTargets.length)) {
      index = 0
      this.index = 0
    }
    if (this.index === -1) {
      index = (this.tipTargets.length -1)
      this.index = (this.tipTargets.length -1)
    }
    this.tipTargets.forEach((el, i) => {
      el.classList.toggle("slide--current", index == i)
    })
  }

  showHideTips() {
    if(this.tipBodyTargets[0].style.display == 'none') {
      this.persistTipPref(false, 'unset', 'Hide Tips')
    } else {
      this.persistTipPref(true, 'none', 'Show Tips')
    }
  }

  persistTipPref(hidden, style, text) {
    localStorage.setItem('hideTips', hidden);
    this.tipBodyTargets.forEach((e) => { e.style.display = style });
    this.showHideTextButtonTargets.forEach((e) => { e.innerHTML = text });
  }
}
