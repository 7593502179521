import { Toggle } from "tailwindcss-stimulus-components"

export default class TipsInline extends Toggle {
  static values = {
    filterText: String,
  }

  connect() {
  }

  filterTipsAndOpenToggle(event) {
    event.preventDefault()
    var event = new CustomEvent('toggleable.StateChanged', {
      bubbles: true,
      cancelable: true,
      detail: this.filterTextValue,
    });

    return document.dispatchEvent(event);
  }

}
