import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["option",  "checkbox", "singleLocation", "submit", "template"];

  initialize() {
    let selected_option = ""
    this.optionTargets.forEach( (elem) => {
      if (elem.checked == true) {
        selected_option = elem.value;
      }
    })

    switch (selected_option) {
      case 'online':
        this.online();
        break;
      case 'single_location':
        this.single_location();
        break;
      default:
        this.default_hide_all();
    }
  }

  online() {
    this.singleLocationTarget.style.display = 'none';
    this.submitTarget.style.display = 'unset';
  }

  single_location() {
    this.singleLocationTarget.style.display = 'unset';
    this.submitTarget.style.display = 'unset';
  }

  default_hide_all(){
    this.singleLocationTarget.style.display = 'none';
    this.submitTarget.style.display = 'none';
  }

  selectRadioOption(event) {
    switch (event.target.value) {
      case 'online':
        this.online();
        break;
      case 'single_location':
        this.single_location();
        break;
      default:
        console.warn("Unexpected value", event.target.value);
    }
  }
}
