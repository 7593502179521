import Sortable from 'stimulus-sortable'
import { FetchRequest, patch } from '@rails/request.js'

export default class extends Sortable {
  connect() {
    super.connect()
    // The sortable.js instance.
    this.sortable

    // Your options
    this.options

    // Your default options
    this.defaultOptions
  }

  // You can override the `end` method here.
  async end(event) {
    let id = event.item.dataset.id
    let url = event.item.dataset.sortableUrl
    let data = new FormData()
    data.append("position", event.newIndex + 1)

    const request = new FetchRequest('patch', url, { body: data })
    const response = await request.perform()
    if (response.ok) {}

    super.end(event)
  }

  // You can set default options in this getter for all sortable elements.
  get defaultOptions() {
    return {
      animation: 500,
    }
  }
}
