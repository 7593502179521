import { Controller } from "stimulus"

export default class extends Controller {
  static values = { path: String }

  redirect(event) {
    if (event.target.closest(".skipped") == null) {
      window.location.href = this.pathValue
    }
  }

}
