import { Controller } from "stimulus"
import Tagify from '@yaireo/tagify'

export default class extends Controller {
  static targets = [ "input" ];

  connect() {
    new Tagify(this.inputTarget, {
      originalInputValueFormat: valuesArr => valuesArr.map(item => item.value).join(';')
    });
  }
}
