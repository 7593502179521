import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['input', 'countValue']

  initialize() {
    this.warningClass = 'over-char-warning';
    this.maxValue = this.data.get("max")
    this.updateCount();
  }

  updateCount() {
    var count = this.maxValue - this.inputTarget.value.length;
    var message = `${count} characters left`;
    this.countValueTarget.textContent = message;
    count < 0 ?
      this.countValueTarget.classList.add(this.warningClass) :
      this.countValueTarget.classList.remove(this.warningClass)
  }

  resetCount() {
    document.getElementsByClassName('character_count')[0].innerHTML = "70 characters left"
  }
}
