import { Toggle } from "tailwindcss-stimulus-components"

export default class TipsToggle extends Toggle {
  static targets = [
    'toggleable'
  ]

  static values = {
    filterText: String
  }

  initialize() {
    let hide_tips = JSON.parse(localStorage.getItem('hideTips'))
    if (hide_tips) {
      this.persistTipsPref(true)
    } else {
      this.persistTipsPref(false)
    }
  }

  connect() {
    this.openToggleClass = "openToggle"
    this.closedToggleClass = "closedToggle"

    document.addEventListener('toggleable.StateChanged', function (event) {
      this.filterTextValue = event.detail
      this.showHideTips(event, true)
    }.bind(this));
  }

  showHideTips(event,filter = false) {
    event.preventDefault()
    this.toggleableTargets.forEach(target => {
      if (filter) {
        this.filterTips(target, this.filterTextValue)
      } else {
        this.clearFilters(target)
      }
      if (target.classList.contains(this.toggleClass)) {
        this.sidebarToggle(target, false)
      } else {
        this.sidebarToggle(target)
      }
    })
  }

  sidebarToggle(elem, tipsAreClosed=false) {
    // Toggle Sidebar
    document.querySelectorAll(".main-tip-nav svg").forEach(elem => {
      elem.classList.toggle('hidden');
    })

    // Toggle Caret(s)
    elem.classList.toggle(this.closedToggleClass)
    elem.classList.toggle(this.openToggleClass)

    this.persistTipsPref(tipsAreClosed)
  }

  clearFilters(elem) {
    elem.querySelectorAll("[data-tip-type]").forEach(elem => {
      elem.classList.remove('hidden');
    });
  }

  filterTips(elem, text) {
    elem.querySelectorAll("[data-tip-type]").forEach(elem => {
      if (elem.getAttribute("data-tip-type") === text) {
        elem.classList.remove('hidden');
      } else {
        elem.classList.add('hidden');
      }
    })
  }

  persistTipsPref(hidden) {
    localStorage.setItem('hideTips', hidden);
  }
}
